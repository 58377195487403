import './collapsible-section.scss';

import React, { ReactNode, useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, Collapse } from 'reactstrap';
import { ClickableDiv } from '../index';

interface Props {
  icon: string;
  label: ReactNode;
  defaultOpen?: boolean;
  children?: ReactNode;
  onToggle?: (isOpen: boolean) => void;
}

const CollapsibleSection = ({ icon, label, defaultOpen, children, ...props }: Props) => {
  const [ isOpen, setIsOpen ] = useState<boolean>(!!defaultOpen);
  const isSSR = typeof window === 'undefined';

  useEffect(() => {
    props.onToggle?.(isOpen);
  }, [ isOpen ]);

  useEffect(() => {
    if (!_.isNil(defaultOpen)) {
      setIsOpen(defaultOpen);
    }
  }, [ defaultOpen ]);

  return (
    <div className="collapsible-section">
      <ClickableDiv onClick={() => setIsOpen(!isOpen)} color="anchor" className="collapsible-section-header child-spacing-x-2">
        <div>
          <h4><i className={icon} /> {label}</h4>
        </div>

        <div>
          <Button aria-label="Toggle Section Collapse" color="info">
            <i className="fas fa-arrow-right" />
          </Button>
        </div>
      </ClickableDiv>
      {!isSSR && (
        <Collapse className="collapsible-section-content" isOpen={isOpen}>
          {children}
        </Collapse>
      )}
    </div>
  );
};

export default CollapsibleSection;
