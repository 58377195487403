import React, { ReactNode } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Markdown, PageSidebar } from '../index';
import BaseLayout from './BaseLayout';
import sidebarData from '../../data/resourcesAndMore.json';

interface Props {
  title: string;
  header: string;
  mainMarkdown: string;
  children?: ReactNode;
}

const InformationLayout = ({ title, header, mainMarkdown, children }: Props) => {
  return (
    <BaseLayout title={title}>
      <Container className="py-5">
        <Row>
          <Col className="mt-3 mt-md-0" md={4} xl={3}>
            <PageSidebar
              label={sidebarData.label}
              links={sidebarData.links}
              image={sidebarData.image}
            />
          </Col>
          <Col className="order-first order-md-last" md={8} xl={9}>
            <h2>{header}</h2>

            <Markdown source={mainMarkdown} />

            {children}
          </Col>
        </Row>
      </Container>
    </BaseLayout>
  );
};

export default InformationLayout;
