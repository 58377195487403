import * as React from 'react';
import * as _ from 'lodash';
import { Link } from 'gatsby';
import siteJson from '../../data/site.json';

interface Props extends Omit<React.HTMLProps<HTMLAnchorElement>, 'ref'> {}

const ExternalLink = ({ href, children, ...rest }: Props) => {
  const local = _.startsWith(href, '/');

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    const isAllowed = _.some(siteJson.externalHosts.allowedHosts, ({ host: allowedHost }) => {
      return _.includes(_.toLower(href), _.toLower(allowedHost));
    });

    // eslint-disable-next-line no-alert
    if (!isAllowed && !window.confirm(siteJson.externalHosts.message)) {
      e.preventDefault();
    }
  };

  if (local && href) {
    return (
      <Link to={href} {...rest} target="_blank" rel="noopener noreferrer">
        {children}
      </Link>
    );
  }

  return (
    <a href={href} {...rest} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      {children}
    </a>
  );
};

export default ExternalLink;
