import React, { useState } from 'react';
import { Button, Col, Collapse, Row } from 'reactstrap';
import { Markdown } from '../components';

interface Props {
  title: string;
  formattedPostDate: string;
  description: string;
  isActive: boolean;
}

const BlogEntry = ({ title, formattedPostDate, description, isActive }: Props) => {
  const [ isExpanded, setIsExpanded ] = useState<boolean>(isActive);
  return (
    <Row>
      <Col>
        <h5>{title}</h5>
        <h6>{formattedPostDate}</h6>
        <Button
          color="primary"
          size="sm"
          className="mb-1"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <span>View Less <i className="fa fa-angle-double-up" /></span>
            : <span>View More <i className="fa fa-angle-double-down" /></span>}
        </Button>

        <Collapse isOpen={isExpanded}>
          <Markdown source={description} />
        </Collapse>
      </Col>
    </Row>
  );
};

export default BlogEntry;
