import React, { ReactNode } from 'react';
import { Markdown } from '../index';
import HashControlledCollapsibleSection from './HashControlledCollapsibleSection';

interface Props {
  id: string;
  defaultOpenIfNoHash?: boolean;
  icon: string;
  mainMarkdown: string;
  label: ReactNode;
  children?: ReactNode;
}

const DefaultCollapsibleMarkdownSection = ({ icon, label, mainMarkdown, id, defaultOpenIfNoHash, children }: Props) => {
  return (
    <HashControlledCollapsibleSection
      id={id}
      defaultOpenIfNoHash={defaultOpenIfNoHash}
      icon={icon}
      label={label}
    >
      <Markdown source={mainMarkdown} />

      {children}
    </HashControlledCollapsibleSection>
  );
};

export default DefaultCollapsibleMarkdownSection;
