import './announcement-overlay.scss';

import React, { useEffect, useState } from 'react';
import store from 'store';
import { Portal } from 'react-portal';

import announcementData from '../../data/announcement.json';
import Markdown from '../Markdown/Markdown';
import CdnImage from '../CdnImage/CdnImage';

export const AnnouncementOverlayInternal = ({
  isActive,
  mainMarkdown,
  forceOpen,
}: typeof announcementData & { forceOpen?: boolean; }) => {
  const [ isOverlayVisible, setIsOverlayVisible ] = useState<boolean>(false);

  useEffect(() => {
    const hasBeenOpened = store.get(getMarkdownStoreKey());

    if (forceOpen || (!hasBeenOpened && isActive)) {
      setIsOverlayVisible(true);
    }
  }, []);

  useEffect(() => {
    if (isOverlayVisible) {
      document.body.classList.add('announcement-visible');
    } else {
      document.body.classList.remove('announcement-visible');
    }
  }, [ isOverlayVisible ]);

  if (!isOverlayVisible) {
    return null;
  }

  function hideOverlay() {
    setIsOverlayVisible(false);
    store.set(getMarkdownStoreKey(), true);
  }

  function getMarkdownStoreKey() {
    return `announcement-md_${getMarkdownHash()}`;
  }

  function getMarkdownHash() {
    return getStringHashcode(mainMarkdown || '');
  }

  function getStringHashcode(input: string) {
    let hash = 0;
    let i;
    let chr;
    for (i = 0; i < input.length; i += 1) {
      chr = input.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = ((hash << 5) - hash) + chr;
      // eslint-disable-next-line no-bitwise
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  if (!isOverlayVisible) {
    return null;
  }

  return (
    <div className="announcement-overlay pt-3">
      <div className="px-5 mb-2">
        <div>
          <CdnImage className="img-fluid" src="/images/logo.png" alt="Archer Heights Credit Union logo" style={{ maxWidth: 200 }} />
        </div>
      </div>

      <div className="flex" style={{ overflowY: 'auto' }}>
        <div className="px-5">
          <Markdown source={mainMarkdown} />
        </div>
      </div>

      <div className="px-5 pt-4 bg-secondary d-flex justify-content-center" style={{ paddingBottom: 80 }}>
        <button type="button" className="btn btn-primary" onClick={hideOverlay}>Continue to Site</button>
      </div>
    </div>
  );
};

export default () => {
  return (
    <Portal>
      <AnnouncementOverlayInternal {...announcementData} />
    </Portal>
  );
};
