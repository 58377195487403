import './page-sidebar.scss';

import React from 'react';
import _ from 'lodash';
import { ExternalLink } from '../index';

interface Props {
  image: string;
  label: string;
  links: { path: string; label: string; }[];
}

const PageSidebar = ({ image, label, links }: Props) => {
  return (
    <div className="page-sidebar bg-primary">
      <img className="img-fluid" src={image} alt="Archer Heights CU Savings" />

      <div className="p-2">
        {!!_.trim(label) && <h5 className="text-white">{label}</h5>}
        {
          links.map((link, i) => (
            <div key={i}>
              <ExternalLink className="text-white" href={link.path}>{link.label}</ExternalLink>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default PageSidebar;
