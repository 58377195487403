import * as React from 'react';
import siteJson from '../../data/site.json';

interface Props extends Omit<React.HTMLProps<HTMLImageElement>, 'crossOrigin'> {
  src: string;
  alt: string;
}

const CdnImage = ({ src, alt, ...props }: Props) => {
  const cdnEnabled = process.env.NODE_ENV !== 'development';

  return (
    <img {...props} src={cdnEnabled ? new URL(src, siteJson.cdnRoot).href : src} alt={alt} />
  );
};

export default CdnImage;
