import '../../assets/scss/styles.scss';

import * as React from 'react';
import { ReactNode } from 'react';
import Helmet from 'react-helmet';
import { Col, Row } from 'reactstrap';
import { Link } from 'gatsby';
import { CdnImage, ExternalLink, FlexRow, VerticalOnlyScrollPanel } from '../index';
import Navbar from '../Navbar/Navbar';
import AnnouncementOverlay from '../AnnouncementOverlay/AnnouncementOverlay';
import siteJson from '../../data/site.json';
import globalData from '../../data/global.json';

interface Props {
  title: string;
  children: ReactNode;
}

const BaseLayout = ({ title, children }: Props) => {
  return (
    <>
      <Helmet
        defaultTitle={siteJson.defaultTitle}
        titleTemplate={`%s | ${siteJson.defaultTitle}`}
      >
        <title>{title}</title>
        <html lang="en" />
        <meta name="theme-color" content="#fff" />
        <meta name="description" content={siteJson.description} />
        <meta name="keywords" content={siteJson.keywords} />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={siteJson.defaultTitle} />
        <meta property="og:url" content="/" />
      </Helmet>
      <Navbar />

      <VerticalOnlyScrollPanel>
        <main style={{ minHeight: '80vh' }}>
          {children}
        </main>

        <footer className="footer py-4 bg-primary text-white">
          <Row>
            <Col sm={4} lg={5}>
              <Row className="p-2 text-center text-sm-left align-items-center text-white">
                <Col sm={12} lg={5} xl={4}>
                  <CdnImage className="img-fluid" src="/images/logo-sm-light.png" alt="Archer Heights CU Logo" />
                </Col>
                <Col sm={12} lg={7} xl={8}>
                  <div className="large-text">(773) 229-1500</div>

                  <div>6554 W Archer Ave, Chicago, IL 60638</div>

                  <div>© {new Date().getFullYear()} Archer Heights Credit Union. All Rights Reserved.</div>

                  <div className="contact">
                    <div>
                      <Link to="/about/#contact">Contact</Link> | <a href={globalData.privacyPolicy} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                    </div>

                    <div>
                      <Link to="/reinvestment">Community Reinvestment Notice</Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="d-flex align-items-center justify-content-center" sm={4} lg={2}>
              <div className="text-center text-white child-spacing-y-2">
                <div>
                  <a aria-label="AHCU's Facebook Page" className="text-white" href="https://www.facebook.com/ahcreditunion/" target="_blank" rel="noreferrer noopener">
                    <i className="fab fa-2x fa-facebook" />
                  </a>
                </div>
                <div>Get Our Mobile App</div>

                <FlexRow>
                  <div className="child-spacing-x-1">
                    <ExternalLink href="https://play.google.com/store/apps/details?id=com.amimembernet.ahcu2&hl=en_US&gl=US">
                      <CdnImage style={{ width: 120 }} src="/images/google-play.svg" alt="Google Play Logo" />
                    </ExternalLink>
                    <ExternalLink href="https://apps.apple.com/ch/app/archer-heights-member-net/id1131408937?l=en">
                      <CdnImage style={{ width: 120 }} src="/images/app-store.svg" alt="Apple App Store Logo" />
                    </ExternalLink>
                  </div>
                </FlexRow>
              </div>
            </Col>
            <Col className="d-flex text-center text-md-right align-items-center align-items-md-end flex-column" sm={4} lg={5}>
              <div className="p-2 child-spacing-y-1">
                <div className="large-text">Routing #{globalData.routingNumber}</div>

                <div>
                  <div><ExternalLink className="text-white" href="https://www.ncua.gov/">Federally Insured by NCUA</ExternalLink></div>
                  <div><ExternalLink className="text-white" href="https://www.hud.gov/">Equal Housing Opportunity</ExternalLink></div>
                  <div><ExternalLink className="text-white" href="https://www.cardinalcompass.com/">Powered by Cardinal Marketing Group</ExternalLink></div>
                </div>
              </div>
            </Col>
          </Row>
        </footer>
      </VerticalOnlyScrollPanel>

      <AnnouncementOverlay />
    </>
  );
};

export default BaseLayout;
