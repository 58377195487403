import React, { useState } from 'react';
import { Button, Collapse, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link } from 'gatsby';
import { CdnImage, ExternalLink, FlexColumn, FlexRow } from '../../components';
import HoverDropdown from '../HoverDropdown/HoverDropdown';
import globalData from '../../data/global.json';

const width = 117;

const Navbar = () => {
  const [ menuCollapsed, setMenuCollapsed ] = useState<boolean>(true);

  function toggleMenu() {
    setMenuCollapsed(!menuCollapsed);
  }

  return (
    <header className="header header-sticky default">
      <nav className="d-flex flex-column flex navbar navbar-static-top navbar-expand-xl">
        <FlexRow className="pl-3" fill justifyBetween>
          <div className="py-2 mr-2">
            <Link to="/" className="navbar-brand">
              <CdnImage className="img-fluid" src="/images/logo.png" alt="Archer Heights Credit Union logo" />
            </Link>
          </div>
          <div className="d-block d-md-none">
            <Collapse className="navbar-collapse" isOpen={!menuCollapsed}>
              <ul className="nav navbar-nav">
                <li className="nav-item dropdown">
                  <Link className="nav-link" to="/save">Save</Link>
                  <Link className="nav-link" to="/borrow">Borrow</Link>
                  <Link className="nav-link" to="/access">24/7 Access</Link>
                  <Link className="nav-link" to="/about">About Us</Link>
                  <Link className="nav-link" to="/rates">Rates</Link>
                </li>
              </ul>
            </Collapse>
          </div>
          <div className="d-none d-md-flex flex-row flex child-spacing-x-2 justify-content-end">
            <FlexRow justifyEnd childSpacingX={3}>
              <Link to="/about/#join" className="d-flex flex flex-column justify-content-center p-2" style={{ width: width }}>
                <div className="text-center">
                  <div><i className="fa fa-2x fa-handshake" /></div>
                  <span className="text-nowrap">How to Join</span>
                </div>
              </Link>

              <ExternalLink href="https://amimembernet.com/ahcu/loan-application" className="d-flex flex flex-column justify-content-center p-2" style={{ width: width }}>
                <div className="text-center">
                  <div><i className="fa fa-2x fa-file-invoice-dollar" /></div>
                  <div>Apply</div>
                </div>
              </ExternalLink>
            </FlexRow>
            <FlexRow className="bg-light text-center">
              <ExternalLink href="https://amimembernet.com/ahcu/login" className="d-flex flex flex-column justify-content-center p-2" style={{ width: width }}>
                <div className="text-center">
                  <div><i className="fa fa-2x fa-sign-in-alt" /></div>
                  <div>Login</div>
                </div>
              </ExternalLink>
              <ExternalLink href="/" className="d-flex flex flex-column justify-content-center p-2" style={{ width: width }}>
                <div className="text-center">
                  <div><i className="fa fa-2x fa-search" /></div>
                  <div>Search</div>
                </div>
              </ExternalLink>
            </FlexRow>
          </div>
          <div className="d-flex d-md-none px-2">
            <Button className="text-primary" color="anchor" type="button" onClick={toggleMenu}>
              <i className="fas fa-lg fa-bars" />
            </Button>
          </div>
        </FlexRow>
      </nav>
      <div className="d-none flex d-md-flex flex-row bg-primary text-white justify-content-between">
        <div className="d-flex flex-row flex child-spacing-x-2 align-items-center justify-content-end">
          <FlexColumn justifyStart className="pl-3 py-2 align-items-start" fill>
            <ul className="nav text-white font-weight-bold justify-content-around d-flex w-100">
              <HoverDropdown nav>
                <DropdownToggle tag={Link} to="/save" className="nav-item text-white">Save</DropdownToggle>
                <DropdownMenu tag="ul">
                  <li><DropdownItem tag={Link} to="/save/#checking"><span>Checking</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/save/#savings"><span>Savings</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/save/#certificates"><span>Certificates</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/save/#iras"><span>IRAs</span></DropdownItem></li>
                </DropdownMenu>
              </HoverDropdown>
              <HoverDropdown nav>
                <DropdownToggle tag={Link} to="/borrow" className="nav-item text-white">Borrow</DropdownToggle>
                <DropdownMenu tag="ul">
                  <li><DropdownItem tag={Link} to="/borrow/#autos"><span>Auto Loans</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/borrow/#motorcycles"><span>Motorcycles</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/borrow/#mortgages"><span>Mortgages</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/borrow/#recreational"><span>Recreational</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/borrow/#creditCards"><span>Credit Cards</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/borrow/#personal"><span>Personal</span></DropdownItem></li>
                </DropdownMenu>
              </HoverDropdown>
              <HoverDropdown nav>
                <DropdownToggle tag={Link} to="/access" className="nav-item text-white">24/7 Access</DropdownToggle>
                <DropdownMenu tag="ul">
                  <li><DropdownItem tag={Link} to="/access/#mobile"><span>Mobile Banking</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/access/#online"><span>Online Banking</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/access/#telephone"><span>Telephone Banking</span></DropdownItem></li>
                  <li><DropdownItem tag={ExternalLink} href="https://www.allpointnetwork.com/locator.html"><span>Free ATM Locator</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/access/#lostCard"><span>Lost or Stolen Card</span></DropdownItem></li>
                </DropdownMenu>
              </HoverDropdown>
              <HoverDropdown nav>
                <DropdownToggle tag={Link} to="/about" className="nav-item text-white">About Us</DropdownToggle>
                <DropdownMenu tag="ul">
                  <li><DropdownItem tag={Link} to="/about/#contact"><span>Contact Us</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/about/#history"><span>Our History</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/about/#news"><span>News & Info</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/about/#staff"><span>Staff</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/about/#join"><span>How to Join</span></DropdownItem></li>
                </DropdownMenu>
              </HoverDropdown>
              <HoverDropdown nav>
                <DropdownToggle tag={Link} to="/rates" className="nav-item text-white">Rates</DropdownToggle>
                <DropdownMenu tag="ul">
                  <li><DropdownItem tag={Link} to="/rates/#loan"><span>Loan Rates</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/rates/#savings"><span>Savings Rates</span></DropdownItem></li>
                  <li><DropdownItem tag={Link} to="/rates/#fees"><span>Fees</span></DropdownItem></li>
                </DropdownMenu>
              </HoverDropdown>
            </ul>
          </FlexColumn>
          <FlexRow justifyAround className="p-2 small" childSpacingX={3} style={{ width: width * 2, background: 'rgba(0,0,0,.5)' }}>
            <FlexColumn center>
              <div><a className="text-white" href="mailto:memberservice@archerheights.com">Email</a></div>
            </FlexColumn>
            <div className="text-center">
              <div>Call</div>
              <div>(773) 229-1500</div>
            </div>
          </FlexRow>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
