import React, { useState } from 'react';
import { Dropdown, DropdownProps } from 'reactstrap';

interface Props extends DropdownProps {}

const HoverDropdown = ({ children, onMouseEnter, onMouseLeave, ...props }: Props) => {
  const [ isOpen, setIsOpen ] = useState<boolean>(false);
  function handleMouseEnter(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    onMouseEnter?.(e);
    setIsOpen(true);
  }

  function handleMouseLeave(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    onMouseLeave?.(e);
    setIsOpen(false);
  }

  return (
    <Dropdown toggle={() => {}} isOpen={isOpen} {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
    </Dropdown>
  );
};

export default HoverDropdown;
