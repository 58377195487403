import React, { Component } from 'react';
import _ from 'lodash';
import {
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
  UncontrolledCarouselProps,
} from 'reactstrap';
import { ExternalLink } from '../index';
import CdnImage from '../CdnImage/CdnImage';

interface Props extends UncontrolledCarouselProps {}

interface State {
  activeIndex: number;
}

class UncontrolledCarousel extends Component<Props, State> {
  private animating: boolean;

  constructor(props: Props) {
    super(props);
    this.animating = false;
    this.state = { activeIndex: props.defaultActiveIndex || 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);

    this.state = {
      activeIndex: 0,
    };
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) {
      return;
    }
    // eslint-disable-next-line react/no-access-state-in-setstate
    const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) {
      return;
    }
    // eslint-disable-next-line react/no-access-state-in-setstate
    const nextIndex = this.state.activeIndex === 0 ? this.props.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex: number) {
    if (this.animating) {
      return;
    }
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { defaultActiveIndex, autoPlay, indicators, controls, items, goToIndex, ...props } = this.props;
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      const key = item.key || item.src;
      const Contents = (
        <>
          <CdnImage className="d-block w-100" src={item.src} alt={item.altText} />
          <CarouselCaption captionText={item.caption} captionHeader={item.header || item.caption} />
        </>
      );

      if (_.isEmpty(item.link)) {
        return (
          <CarouselItem
            onExiting={this.onExiting}
            onExited={this.onExited}
            key={key}
          >
            {Contents}
          </CarouselItem>
        );
      }

      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={key}
        >
          <ExternalLink href={item.link}>
            {Contents}
          </ExternalLink>
        </CarouselItem>
      );
    });

    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
        ride={autoPlay ? 'carousel' : undefined}
        {...props}
      >
        {indicators && (
          <CarouselIndicators
            items={items}
            activeIndex={props.activeIndex || activeIndex}
            onClickHandler={goToIndex || this.goToIndex}
          />
        )}
        {slides}
        {controls && (
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={props.previous || this.previous}
          />
        )}
        {controls && (
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={props.next || this.next}
          />
        )}
      </Carousel>
    );
  }
}

export default UncontrolledCarousel;
