import './collapsible-section.scss';

import React, { ReactNode } from 'react';
import { useLocation } from '@reach/router';
import { CollapsibleSection } from '../index';

interface Props {
  id: string;
  icon: string;
  label: ReactNode;
  defaultOpenIfNoHash?: boolean;
  children?: ReactNode;
  onToggle?: (isCollapsed: boolean) => void;
}

const HashControlledCollapsibleSection = ({ id, defaultOpenIfNoHash, children, ...props }: Props) => {
  const { hash } = useLocation();
  const shouldDefaultOpen = (!hash && defaultOpenIfNoHash) || hash.substr(1) === id;

  return (
    <CollapsibleSection {...props} defaultOpen={shouldDefaultOpen}>
      {children}
    </CollapsibleSection>
  );
};

export default HashControlledCollapsibleSection;
